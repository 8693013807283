import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectSiteUrl } from '../../features/siteUrl/siteUrlSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { Helmet } from 'react-helmet'

const AppHeader = () => {
	const [categories, setCategories] = useState([])
	const url = useSelector(selectSiteUrl)

	useEffect(() => {
		if (url) {
			console.log(url)
			fetch(`${url}NavbarCategory`)
				.then((response) => response.json())
				.then((data) => setCategories(data))
				.catch((error) => console.error('Error fetching data:', error))
		}
	}, [url])

	return (
		<>
			<Helmet>
				<title>Добрый дом - Главная страница</title>
				<meta
					name='description'
					content=' Наша компания специализируется на строительстве частных домов в Иркутске из различных материалов, таких как бетон и дерево. Мы предлагаем индивидуальный подход к каждому проекту, учитывая пожелания и потребности клиента. 🌲 Строим дома, которые сочетают в себе качество, надежность и уют, создавая комфортное пространство для жизни. 🏗️ Наша команда профессионалов обладает большим опытом в строительстве и гарантирует высокое качество работ. 🔨 Мы используем современные технологии и материалы, чтобы каждый дом был долговечным и функциональным. 🌟 Доверьте нам строительство вашего идеального дома, и мы превратим ваши мечты в реальность! 🏠 Вместе мы создадим уникальное жилище, которое будет радовать вас и вашу семью долгие годы!'
				/>
				<meta
					name='keywords'
					content='дом, строительство, Иркутск, коттедж, проект, дизайн, уют, комфорт, отделка, материалы, архитектура, брус, кирпич, дача, загородный, фундамент, электрика, сантехника, благоустройство, земельный участок, подрядчик, услуги, каркасный, экология, энергоэффективность, интерьеры, ландшафт, отзывы, цены, застройщик, строительство под ключ, индивидуальный проект, ремонт, архитектурное бюро, строительство бань, стиль, фасад, окна, двери, терраса, веранда, крыша, планировка, малые архитектурные формы, современные технологии, безопасность, законность'
				/>
				<meta name='author' content='Добрый дом' />
			</Helmet>
			<div id='navbar' className='navbar navbar-expand-lg'>
				<div className='container'>
					<a href='/' className='navbar-brand'>
						Добрый дом<span>.</span>
					</a>
					<button
						className='navbar-toggler'
						type='button'
						data-bs-toggle='collapse'
						data-bs-target='#navbarSupportedContent'
						aria-controls='navbarSupportedContent'
						aria-expanded='false'
						aria-label='Toggle navigation'
					>
						<FontAwesomeIcon className='fa-icon' icon={faBars} />
					</button>

					<div className='collapse navbar-collapse' id='navbarSupportedContent'>
						<ul className='navbar-nav ms-auto'>
							{categories.map((category) => (
								<li key={category.id} className='nav-item'>
									<a className='nav-link' href={'/#' + category.anchor}>
										{category.category_name}
									</a>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</>
	)
}

export default AppHeader
